import range from "lodash/range";

export const VIEWPORT_RADIUS = 15;
export const MAP_SQUARE_PIXEL_HEIGHT = 20;

const realCoordsToViewport = ({ x, y }, gameData) => ({
  x: x - gameData.center.x + VIEWPORT_RADIUS,
  y: y - gameData.center.y + VIEWPORT_RADIUS,
});

const viewportCoordsToReal = ({ x, y }, gameData) => ({
  x: x + gameData.center.x - VIEWPORT_RADIUS,
  y: y + gameData.center.y - VIEWPORT_RADIUS,
});

export const initMapView = (gameData) => {
  range(VIEWPORT_RADIUS * 2 + 1).forEach((row, y) => {
    const rowElement = $("<div />").appendTo("#gameContent");
    range(VIEWPORT_RADIUS * 2 + 1).forEach((x) =>
      $(
        `<img class="terrainImage" src="black.png" style="z-index: 20;" width="${MAP_SQUARE_PIXEL_HEIGHT}" height="${MAP_SQUARE_PIXEL_HEIGHT}px" />`
      )
        .on("click", () =>
          gameData.viewportImageClickHandler(
            viewportCoordsToReal({ x, y }, gameData)
          )
        )
        .appendTo(rowElement)
    );
  });
  gameData.teams.forEach((team, idx) => {
    $(`<div class="teamRow" id="teamRow${idx}"/>`)
      .append(`<div class="status" />`)
      .append(
        `<div><img src="medical.png"/><span class="health">${team.health}</span></div>`
      )
      .append(
        `<div><img src="trees.png"/><span class="wood">${team.wood}</span></div>`
      )
      .append(
        `<div><img src="rock.png"/><span class="stone">${team.stone}</span></div>`
      )
      .appendTo($("#teamContent"));
    $(
      `<img id="team${idx}Image" class="team" width="${MAP_SQUARE_PIXEL_HEIGHT}" height="${MAP_SQUARE_PIXEL_HEIGHT}" src="person.png" style="z-index: 30; position: absolute;" />`
    )
      .on("click", () => gameData.viewportPlayerClickHandler(team.number))
      .appendTo($("#players"));
    $(
      `<div id="team${idx}Label" class="team" style="color: ${
        team.number === gameData.selectedTeam ? "white" : "lightgray"
      }; z-index: 40; position: absolute;" >${team.number}</div>`
    )
      .on("click", () => gameData.viewportPlayerClickHandler(team.number))
      .appendTo($("#players"));
  });
  gameData.aliens.forEach((alien) =>
    $(
      `<img id="alien${alien.number}Image" class="alien" width="${MAP_SQUARE_PIXEL_HEIGHT}" height="${MAP_SQUARE_PIXEL_HEIGHT}" src="alien.png" style="z-index: 30; position: absolute;" />`
    ).appendTo($("#players"))
  );
};

const placeImage = (element, location, gameData, xOffset = 0, yOffset = 0) => {
  const viewportCoords = realCoordsToViewport(location, gameData);
  return element
    .css("left", viewportCoords.x * MAP_SQUARE_PIXEL_HEIGHT + xOffset)
    .css("top", viewportCoords.y * MAP_SQUARE_PIXEL_HEIGHT + yOffset)
    .show();
};

const FIRE_TEMPLATE = $(
  `<img class="fire" width="${MAP_SQUARE_PIXEL_HEIGHT}" height="${MAP_SQUARE_PIXEL_HEIGHT}" src="fire.png" style="z-index: 30; position: absolute;" />`
);

export const drawMap = (gameData, viewPort) => {
  // TODO: this may be a little inefficient for selecting
  // There is a baseline visibilit that is added below
  $(".terrainImage").css("opacity", gameData.ambientLight + 0.2);
  viewPort.grid.forEach((row, y) => {
    const rowElement = $("#gameContent").find("div").eq(y); // Compile once here
    row.forEach(
      (item, x) =>
        (rowElement
          .find("img")
          .eq(x)
          .toggleClass(
            "fuzzy",
            !item.visible
          )[0].src = `${item.square.lastKnown}.png`)
    );
  });
  $("#players img.fire").remove();
  $("#players img.alien").hide();
  $("#players img.team").hide();
  $("#players div.team").hide();
  viewPort.fires.forEach((fire) =>
    placeImage(FIRE_TEMPLATE.clone(), fire.location, gameData).appendTo(
      $("#players")
    )
  );
  viewPort.aliens.forEach((alien) =>
    placeImage($(`#alien${alien.number}Image`), alien.location, gameData)
  );
  viewPort.teams.forEach((team) => {
    placeImage($(`#team${team.number}Image`), team.location, gameData);
    placeImage(
      $(`#team${team.number}Label`),
      team.location,
      gameData,
      20,
      -10
    ).css(
      "color",
      team.number === gameData.selectedTeam ? "white" : "lightgray"
    );
  });
};
