import range from "lodash/range";
import random from "lodash/random";

export default class TerrainGenerator {
  constructor(settings) {
    this.map = [];
    this.settings = settings;
  }

  getMap() {
    return this.map;
  }

  createBaseMap() {
    // Fills the whole map with saltwater.
    range(this.settings.worldWidth).forEach((x) => {
      this.map.push([[]]);
      range(this.settings.worldHeight).forEach((y) => {
        this.map[x][y] = {
          terrain: "saltwater",
          lastKnown: "black",
        };
      });
    });
  }

  randomCoordsOnIsland(minDistance) {
    // Produces coordinates that are within the boundaries of the island.
    var radians = random(360) * (Math.PI / 180);
    var distance = random(minDistance, this.settings.worldWidth / 2 - 15);
    var centerX = Math.floor(
      this.settings.worldWidth / 2 + Math.sin(radians) * distance
    );
    var centerY = Math.floor(
      this.settings.worldHeight / 2 + Math.cos(radians) * distance
    );
    return { x: centerX, y: centerY };
  }

  addRandomBlobs(type, num, minRad, maxRad) {
    // Adds blobs of the given type randomly about
    // the island.
    range(num).forEach(() => {
      var coords = this.randomCoordsOnIsland(20);
      var rad = random(minRad, maxRad);
      this.addBlob(coords.x, coords.y, rad, type);
    });
  }

  addRandomTemplate(tmpl) {
    const coords = this.randomCoordsOnIsland(20);
    tmpl.form.forEach((row, y) => {
      row.split("").forEach((square, x) => {
        if (tmpl[square]) {
          this.map[coords.x + x][coords.y + y].terrain = tmpl[square];
        }
      });
    });
  }

  inBounds(x, y) {
    return (
      x >= 0 &&
      x < this.settings.worldWidth &&
      y >= 0 &&
      y < this.settings.worldHeight
    );
  }

  addBlob(centerX, centerY, rad, type) {
    range(10).forEach(() => {
      var radians = random(360) * (Math.PI / 180);
      var distance = random(3, rad);
      var radius = random(rad / 2);
      if (radius < 4) {
        this.addCircle(
          Math.floor(centerX + Math.sin(radians) * distance),
          Math.floor(centerY + Math.cos(radians) * distance),
          radius,
          type
        );
      } else {
        this.addBlob(
          Math.floor(centerX + Math.sin(radians) * distance),
          Math.floor(centerY + Math.cos(radians) * distance),
          radius,
          type
        );
      }
    });
  }

  addCircle(centerX, centerY, rad, type) {
    // Goes through the radius grid; when the tile is
    // in the radius, it paints it with this terrain.
    range(Math.floor(centerX - rad), Math.floor(centerX + rad + 1)).forEach(
      (x) => {
        range(Math.floor(centerY - rad), Math.floor(centerY + rad + 1)).forEach(
          (y) => {
            var width = Math.abs(centerX - x);
            var height = Math.abs(centerY - y);
            var dist = Math.hypot(width, height);
            if (dist <= rad && this.inBounds(x, y)) {
              this.map[x][y].terrain = type;
            }
          }
        );
      }
    );
  }
}
