import compose from "lodash/fp/compose";
import clone from "lodash/fp/clone";
import identity from "lodash/fp/identity";
import pickBy from "lodash/fp/pickBy";
import mapValues from "lodash/fp/mapValues";
import range from "lodash/range";
import random from "lodash/random";
import curry from "lodash/fp/curry";
import get from "lodash/fp/get";
import TerrainGenerator from "./TerrainGenerator";
import {
  data as terrainData,
  castle,
  ruin,
  palmLayout,
  VISIBILITY_BLOCKING_TERRAIN,
  COMBUSTIBLE_TERRAIN,
} from "./terrain";
import { updateStatusView, initStatusView } from "./statusView";
import { VIEWPORT_RADIUS, drawMap, initMapView } from "./mapView";

const HANDLE_DAY_NIGHT = true;
let VISIBLE_RADIUS = 13;
const MAP_WIDTH = 400;
const MAP_HEIGHT = 400;
const MOVE_VIEW_INCREMENT = 4;
const TURN_TIMEOUT = 100;
const MAX_MATERIALS_CARRIED = 500;
const NUMBER_OF_TEAMS = 10;
const NUMBER_OF_ALIENS = 40;
const IDLE_TURNS_BEFORE_PAUSE = (1000 / TURN_TIMEOUT) * 60; // last val is seconds
const buildings = compose(pickBy(identity), mapValues("build"))(terrainData);

const mapSettings = {
  worldHeight: MAP_HEIGHT,
  worldWidth: MAP_WIDTH,
};
const god = new TerrainGenerator(mapSettings);

const gameData = {
  center: {
    x: 200,
    y: 200,
  },
  map: [],
  aliens: [],
  fires: [],
  teams: [],
  turn: 0,
  selectedTeam: 0,
  imgCallback: () => {},
  trackTeam: true,
  paused: false,
  lastInteraction: 0,
  showMiniMap: true,
  viewportImageClickHandler: (coords) => {
    gotUserInteraction();
    gameData.imgCallback(coords);
  },
  viewportPlayerClickHandler: (teamNumber) =>
    setGoalToFollow(teamNumber, currentTeam()),
  ambientLight: 1.0,
};

const currentTeam = () => gameData.teams[gameData.selectedTeam];

const startGame = () => {
  gameData.map = createMap();
  gameData.teams = generateTeams();
  gameData.aliens = generateAliens();
  setCenterToTeam(0);
  gameData.teams.forEach((team) => exposeNewMapAreas(team.location));
  initMapView(gameData);
  initStatusView(gameData);
  initKeyHandler();
  drawMiniMap();
  nextTurn();
};

const initKeyHandler = () => $("body").on("keydown", keyDown);

const generateAliens = () =>
  range(NUMBER_OF_ALIENS).map((idx) => ({
    number: idx,
    location: god.randomCoordsOnIsland(0),
    goal: {
      type: "roam",
    },
  }));

const generateTeams = () =>
  range(NUMBER_OF_TEAMS).map((idx) => ({
    number: idx,
    location: god.randomCoordsOnIsland(0),
    wood: 0,
    stone: 0,
    health: 80,
  }));

const getViewPort = (map, { x: centerX, y: centerY }) => ({
  grid: range(-VIEWPORT_RADIUS, VIEWPORT_RADIUS + 1).map((y) =>
    range(-VIEWPORT_RADIUS, VIEWPORT_RADIUS + 1).map((x) => ({
      square: mapSquare({ x: centerX + x, y: centerY + y }),
      visible: withinViewOfAnyTeam({ x: centerX + x, y: centerY + y }),
    }))
  ),
  aliens: gameData.aliens.filter(visibleInViewport),
  fires: gameData.fires.filter(visibleInViewport),
  teams: gameData.teams.filter(inViewport),
});

const setCenterToTeam = (teamIndex) => {
  gameData.selectedTeam = teamIndex;
  const team = gameData.teams[teamIndex];
  setViewportCenter(team.location);
  gameData.trackTeam = true;
  gameData.imgCallback = defaultImgCallback; // reset click handler
  drawMiniMap();
};

const mapSquare = ({ x, y }) =>
  get(["map", x, y])(gameData) || { terrain: "saltwater", lastKnown: "black" };

// a > b => -1, a < b => 1, a === b => 0
const gtLtEq = (a, b) => (a === b ? 0 : a > b ? -1 : 1);

const moveTeam = (team, target) => {
  const mvX = gtLtEq(team.location.x, target.x);
  const mvY = gtLtEq(team.location.y, target.y);
  if (mvX === 0 && mvY === 0) {
    return false;
  }
  const nextLocation = {
    x: team.location.x + mvX,
    y: team.location.y + mvY,
  };
  // Check to see if suggested move is valid
  // If passage is less than ten, you can move away from it, but not into it
  if (terrainData[mapSquare(nextLocation).terrain].passage <= 10) {
    return false;
  }
  // If we want to slow when IN terrain, use team.location;
  // otherwise if it's ENTERING terrain, use nextLocation.
  if (random(100) < terrainData[mapSquare(team.location).terrain].passage) {
    team.location = clone(nextLocation);
  }
  return true;
};

const findNearestVisible = ({ x, y }, terrain) => {
  let found;
  range(1, VISIBLE_RADIUS + 1).some((distance) =>
    range(distance + 1).some((offset) => {
      const variations = [
        { x: x + offset, y: y + distance },
        { x: x + offset, y: y - distance },
        { x: x - offset, y: y + distance },
        { x: x - offset, y: y - distance },
        { x: x + distance, y: y + offset },
        { x: x + distance, y: y - offset },
        { x: x - distance, y: y + offset },
        { x: x - distance, y: y - offset },
      ];
      found = variations.filter(
        (item) => mapSquare(item).lastKnown === terrain
      )[0];
      return !!found;
    })
  );
  return found;
};

const distanceBetween = (a, b) => Math.hypot(a.x - b.x, a.y - b.y);

const forageFor = (terrain, changesTo, materialName) => (team) => {
  if (team.moveTo) {
    const didMove = moveTeam(team, team.moveTo);
    if (!didMove) {
      delete team.moveTo;
      // need to detect if they couldn't move because they reached target
      // or because it's blocked.  Only delete goal if blocked.
    }
  } else {
    if (mapSquare(team.location).terrain === terrain) {
      // Change to use square to track amount left
      team[materialName] += 10;
      mapSquare(team.location).terrain = changesTo;
      if (team[materialName] > MAX_MATERIALS_CARRIED) {
        team[materialName] = MAX_MATERIALS_CARRIED;
        delete team.goal;
        return;
      }
    }
    const nearest = findNearestVisible(team.goal.center, terrain);
    if (!nearest) {
      // Could always have them re-center here
      delete team.goal;
    } else {
      // if you have to travel a ways, use that as new center
      if (distanceBetween(team.location, nearest) > 5) {
        team.goal.center = clone(nearest);
      }
      team.moveTo = nearest;
    }
  }
};

const setGoal = (team, goal, moveTo) => {
  team.goal = goal;
  team.moveTo = moveTo;
};

const setGoalToForage = curry((terrain, team) =>
  setGoal(team, {
    type: terrain,
    center: clone(team.location),
  })
);

const setGoalToHalt = (team) => setGoal(team, { type: "halt" });

const setGoalToRoam = (team) =>
  setGoal(team, {
    type: "roam",
    timeout: 30,
  });

const setGoalToGoto = (coords, team) => setGoal(team, { type: "goto" }, coords);

const setGoalToBuild = (building, coords, team) =>
  setGoal(team, { type: "buildTo", building }, coords);

const setCallbackToBuild = curry(
  (building, team) =>
    (gameData.imgCallback = (coords) => setGoalToBuild(building, coords, team))
);

const setGoalToFollow = curry((followingNumber, team) =>
  setGoal(team, {
    type: "follow",
    following: followingNumber,
  })
);

const goto = (team) => moveTeam(team, team.moveTo) || setGoalToHalt(team);

const roam = (team) => {
  if (!team.moveTo || !moveTeam(team, team.moveTo)) {
    team.moveTo = {
      x: team.location.x + random(20) - 10,
      y: team.location.y + random(20) - 10,
    };
  }
  if (team.goal.timeout) {
    team.goal.timeout--;
    if (!team.goal.timeout) {
      delete team.goal;
    }
  }
};

const burn = (fire) => {
  fire.goal.fuel -= 5;
  if (random(100) < 20) {
    const coords = {
      x: fire.location.x + random(2) - 1,
      y: fire.location.y + random(2) - 1,
    };
    tryToBurn(coords);
  }
  if (fire.goal.fuel <= 0) {
    const location = mapSquare(fire.location);
    location.terrain = terrainData[location.terrain].burnsTo;
  }
};

const buildToTurn = (team) => {
  // TODO: this seems to build AND move
  attemptBuild(team, team.goal.building);
  return moveTeam(team, team.moveTo);
};

const buildTo = (team) => {
  if (!buildToTurn(team)) {
    setGoalToHalt(team);
    gameData.imgCallback = defaultImgCallback;
  }
};

const follow = (team) => {
  const otherTeamLocation = gameData.teams[team.goal.following].location;
  if (distanceBetween(team.location, otherTeamLocation) > 3) {
    moveTeam(team, otherTeamLocation);
  }
};

const goalHandlers = {
  trees: forageFor("trees", "stumps", "wood"),
  rock: forageFor("rock", "rubble", "stone"),
  goto: goto,
  roam: roam,
  burn: burn,
  buildTo: buildTo,
  halt: () => {},
  follow: follow,
};

const exposeNewMapAreas = (coords) =>
  range(-VISIBLE_RADIUS, VISIBLE_RADIUS + 1).forEach((offsetY) => {
    range(-VISIBLE_RADIUS, VISIBLE_RADIUS + 1).forEach((offsetX) => {
      if (Math.hypot(offsetX, offsetY) <= VISIBLE_RADIUS) {
        const squareCoords = { x: coords.x + offsetX, y: coords.y + offsetY };
        const square = mapSquare(squareCoords);
        if (aCanSeeB(coords, squareCoords)) {
          square.lastKnown = square.terrain;
        }
      }
    });
  });

const setViewportCenter = (coords) => (gameData.center = clone(coords));
const idleTimeoutExceeded = () =>
  !gameData.paused &&
  gameData.turn - gameData.lastInteraction > IDLE_TURNS_BEFORE_PAUSE;

const determineNextTeamGoal = (team) => {
  if (team.stone < MAX_MATERIALS_CARRIED) {
    const location = findNearestVisible(team.location, "rock");
    if (location) {
      setGoalToForage("rock", team);
      return;
    }
  }
  if (team.wood < MAX_MATERIALS_CARRIED) {
    const location = findNearestVisible(team.location, "trees");
    if (location) {
      setGoalToForage("trees", team);
      return;
    }
  }
  setGoalToRoam(team);
};

const nextTurn = () => {
  gameData.teams.filter((team) => !team.goal).forEach(determineNextTeamGoal);
  gameData.teams
    .filter((team) =>
      gameData.fires.some((fire) => sameLocation(team.location, fire.location))
    )
    .forEach((team) => (team.health -= 5));
  gameData.teams
    .filter((team) => mapSquare(team.location).terrain === "medical")
    .forEach((team) => (team.health = Math.min(100, team.health + 5)));
  gameData.teams
    .concat(gameData.aliens)
    .concat(gameData.fires)
    .filter((team) => get("goal.type")(team))
    .forEach((team, idx) => goalHandlers[team.goal.type](team, idx));
  gameData.fires = gameData.fires.filter((fire) => fire.goal.fuel > 0);
  gameData.teams.forEach((team) => exposeNewMapAreas(team.location));
  gameData.turn++;

  if (HANDLE_DAY_NIGHT) {
    // determines luminosity factor in a 24 hour timeclock
    const hour = (gameData.turn / 20 + 8) % 24;
    gameData.ambientLight = Math.min(
      Math.max(-Math.cos(hour / 3.82) + 0.5, 0),
      1
    );
    VISIBLE_RADIUS = Math.floor(8 * gameData.ambientLight + 5);
  }

  gameData.trackTeam && setViewportCenter(currentTeam().location);

  drawScreen();
  idleTimeoutExceeded() && togglePause();
  !gameData.paused && TURN_TIMEOUT && setTimeout(nextTurn, TURN_TIMEOUT);
};

const defaultImgCallback = (coords) => setGoalToGoto(coords, currentTeam());

const squareIsBlocked = (xRatio, yRatio, centerX, centerY) => (radius) => {
  const x = Math.floor(radius * xRatio + 0.5); // radius * slope, rounded
  const y = Math.floor(radius * yRatio + 0.5); // radius * slope, rounded
  return VISIBILITY_BLOCKING_TERRAIN.includes(
    mapSquare({ x: x + centerX, y: y + centerY }).terrain
  );
};

const aCanSeeB = (a, b) => {
  const diffX = b.x - a.x;
  const diffY = b.y - a.y; // Upside-down due to coordinate geometry
  if (diffX === 0 && diffY === 0) {
    return true;
  }
  const hypot = Math.hypot(diffX, diffY);
  if (hypot > VISIBLE_RADIUS) {
    return false;
  }
  const distance = range(1, Math.floor(hypot)); // for each length from center
  return !distance.some(
    squareIsBlocked(diffX / hypot, diffY / hypot, a.x, a.y)
  );
};

const attemptBuild = (team, buildingName) => {
  const building = buildings[buildingName];
  if (
    building.buildOn.includes(mapSquare(team.location).terrain) &&
    team[building.material] >= building.amount
  ) {
    team[building.material] -= building.amount;
    mapSquare(team.location).terrain = buildingName;
  }
};

const changeView = (offsetX, offsetY) => {
  gameData.center.x += offsetX;
  gameData.center.y += offsetY;
  gameData.trackTeam = false;
};

const togglePause = () => {
  gameData.paused = !gameData.paused;
  $("#gameContent").css("opacity", gameData.paused ? 0.4 : 1);
  $("#players").css("opacity", gameData.paused ? 0.4 : 1);
  console.log(gameData.paused ? "Game Paused" : "Game Resumed");
  if (!gameData.paused) {
    nextTurn();
  }
};

const tryToBurn = (location) => {
  if (
    COMBUSTIBLE_TERRAIN.includes(mapSquare(location).terrain) &&
    !gameData.fires.some(
      (fire) => fire.location.x === location.x && fire.location.y === location.y
    )
  ) {
    gameData.fires.push({
      location,
      goal: {
        type: "burn",
        fuel: 100,
      },
    });
  }
};

const ignite = (team) => tryToBurn(team.location);

const toggleMiniMap = () => {
  gameData.showMiniMap = !gameData.showMiniMap;
  if (gameData.showMiniMap) {
    $("#mapContent").show();
    drawMiniMap();
  } else {
    $("#mapContent").hide();
  }
};

const sameLocation = (a, b) => a.x === b.x && a.y === b.y;

const keyActions = {
  27: togglePause, // esc
  32: setGoalToHalt, // space
  37: () => changeView(-MOVE_VIEW_INCREMENT, 0), // left
  38: () => changeView(0, -MOVE_VIEW_INCREMENT), // up
  39: () => changeView(MOVE_VIEW_INCREMENT, 0), // right
  40: () => changeView(0, MOVE_VIEW_INCREMENT), // down
  48: () => setCenterToTeam(0), // team 0
  49: () => setCenterToTeam(1), // team 1
  50: () => setCenterToTeam(2), // team 2
  51: () => setCenterToTeam(3), // team 3
  52: () => setCenterToTeam(4), // team 4
  53: () => setCenterToTeam(5), // team 5
  54: () => setCenterToTeam(6), // team 6
  55: () => setCenterToTeam(7), // team 7
  56: () => setCenterToTeam(8), // team 8
  57: () => setCenterToTeam(9), // team 9
  65: determineNextTeamGoal, // a
  66: setCallbackToBuild("bridge"), // b
  70: setCallbackToBuild("woodfence"), // f
  73: ignite, // i
  77: toggleMiniMap, // m
  82: setGoalToForage("rock"), // r
  84: setGoalToForage("trees"), // t
  87: setCallbackToBuild("wall"), // w
};

const gotUserInteraction = () => (gameData.lastInteraction = gameData.turn);

const keyDown = (evt) => {
  gotUserInteraction();
  // provide current team if useful to function
  keyActions[evt.which] && keyActions[evt.which](currentTeam());
  drawScreen();
};

const inViewport = (team) =>
  Math.abs(gameData.center.x - team.location.x) <= VIEWPORT_RADIUS &&
  Math.abs(gameData.center.y - team.location.y) <= VIEWPORT_RADIUS;

const aWithinVisibilityOfB = (a, b) =>
  Math.hypot(a.x - b.x, a.y - b.y) <= VISIBLE_RADIUS;

const withinViewOfAnyTeam = (coords) =>
  gameData.teams
    .filter((team) => aWithinVisibilityOfB(team.location, coords))
    .some((team) => aCanSeeB(team.location, coords));

const drawMiniMap = () => {
  // Brute-force map
  const canvasContext = $("#mapContent")[0].getContext("2d");
  canvasContext.lineWidth = 1;
  range(MAP_HEIGHT / 4).forEach((y) => {
    range(MAP_WIDTH / 4).forEach((x) => {
      const lastKnown = mapSquare({ x: x * 4, y: y * 4 }).lastKnown;
      canvasContext.fillStyle = terrainData[lastKnown].mapColor || "black";
      canvasContext.fillRect(x, y, 1, 1);
    });
  });
  const location = currentTeam().location;
  canvasContext.fillStyle = "red";
  canvasContext.fillRect(location.x / 4 - 1, location.y / 4 - 1, 3, 3);
};

const visibleInViewport = (subject) =>
  inViewport(subject) &&
  gameData.teams.some((team) => aCanSeeB(team.location, subject.location));

const drawScreen = () => {
  drawMap(gameData, getViewPort(gameData.map, gameData.center));
  updateStatusView(gameData);

  gameData.showMiniMap && gameData.turn % 10 === 0 && drawMiniMap();
};

const createMap = () => {
  god.createBaseMap();
  god.addCircle(
    mapSettings.worldWidth / 2,
    mapSettings.worldHeight / 2,
    mapSettings.worldWidth / 2 - 15,
    "grass"
  );
  god.addRandomBlobs("trees", 200, 1, 20);
  god.addRandomBlobs("rock", 20, 3, 15);
  god.addRandomBlobs("sand", 20, 3, 20);
  god.addRandomBlobs("water", 40, 3, 20);
  range(4).forEach(() => god.addRandomTemplate(castle));
  range(6).forEach(() => god.addRandomTemplate(ruin));
  range(6).forEach(() => god.addRandomTemplate(palmLayout));
  return god.getMap();
};

$(document).ready(startGame);
