const BUILDABLE_TERRAIN = ["grass", "sand", "rubble", "stumps"];
export const VISIBILITY_BLOCKING_TERRAIN = [
  "trees",
  "rock",
  "wall",
  "woodfence",
];
export const COMBUSTIBLE_TERRAIN = ["trees", "woodfence", "bridge"];

export const castle = {
  w: "wall",
  " ": "grass",
  "+": "medical",
  form: [
    "               ",
    " www       www ",
    " w w  w w  w w ",
    " w wwww wwww w ",
    " w           w ",
    " w           w ",
    " w           w ",
    " w     +     w ",
    " w           w ",
    " w           w ",
    " w wwwwwwwww w ",
    " www       www ",
    "               ",
  ],
};
export const ruin = {
  w: "wall",
  ".": "rubble",
  form: [
    "   ww w  ",
    " www  www",
    " w..    .",
    " w.     w",
    " w      .",
    " w  ..   ",
  ],
};
export const palmLayout = {
  t: "trees",
  ".": "water",
  form: [
    "  .....  ",
    "  .t.t.  ",
    " ..ttt.. ",
    "..ttttt..",
    ".tt.t.tt.",
    " ...t... ",
    "  ..t..  ",
    " ..ttt.. ",
    "  .....  ",
  ],
};

export const data = {
  // Only used for map.
  black: {
    mapColor: "darkgray",
  },
  bridge: {
    passage: 100,
    build: {
      material: "wood",
      amount: 30,
      buildOn: ["water"],
    },
    burnsTo: "water",
    mapColor: "brown",
  },
  water: {
    passage: 20,
    mapColor: "blue",
  },
  trees: {
    passage: 50,
    burnsTo: "stumps",
    mapColor: "darkgreen",
  },
  grass: {
    passage: 100,
    mapColor: "green",
  },
  sand: {
    passage: 90,
    mapColor: "lightbrown",
  },
  rock: {
    passage: 30,
    mapColor: "gray",
  },
  stumps: {
    passage: 90,
    mapColor: "green",
  },
  rubble: {
    passage: 90,
    mapColor: "lightbrown",
  },
  saltwater: {
    passage: 0,
    mapColor: "blue",
  },
  wall: {
    passage: 10,
    build: {
      material: "stone",
      amount: 60,
      buildOn: BUILDABLE_TERRAIN,
    },
    mapColor: "lightgray",
  },
  woodfence: {
    passage: 10,
    build: {
      material: "wood",
      amount: 30,
      buildOn: BUILDABLE_TERRAIN,
    },
    burnsTo: "stumps",
    mapColor: "brown",
  },
  medical: {
    passage: 100,
    build: {
      material: "wood",
      amount: 30,
      buildOn: BUILDABLE_TERRAIN,
    },
    mapColor: "white",
  },
};
