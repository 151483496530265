import get from "lodash/fp/get";

export const initStatusView = (gameData) =>
  gameData.teams.forEach((team, idx) =>
    $(`<div class="teamRow" id="teamRow${idx}"/>`)
      .append(`<div class="status" />`)
      .append(
        `<div><img src="medical.png"/><span class="health">${team.health}</span></div>`
      )
      .append(
        `<div><img src="trees.png"/><span class="wood">${team.wood}</span></div>`
      )
      .append(
        `<div><img src="rock.png"/><span class="stone">${team.stone}</span></div>`
      )
  );

export const updateStatusView = (gameData) =>
  gameData.teams.forEach((team, idx) => {
    const row = $(`#teamRow${idx}`);
    row
      .find(".status")
      .toggleClass("selected", idx === gameData.selectedTeam)
      .text(`Team ${idx}: ${get("goal.type")(team) || "Idling"}`);
    row.find(".health").text(team.health);
    row.find(".wood").text(team.wood);
    row.find(".stone").text(team.stone);
  });
